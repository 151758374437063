import { FC, ReactElement, ReactNode, useEffect, ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ThemeProvider, CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { BaseStyles, ModalProvider } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import { captureException } from '@sentry/nextjs';
import { NextPage } from 'next';
import { elb } from '@elbwalker/walker.js';
import Tagger from '@elbwalker/tagger';

import { withNextRedux } from 'shared/store';
import { Layout } from 'shared/components/Layout';
import { provideCatalog } from 'shared/store/catalog/actions';
import { IProductGalleryItem } from 'shared/infra/commerceLayer/prices';
import { OptimizelyProvider } from 'shared/services/optimizely/OptimizelyProvider';
import { dispatchPageViewEvent } from 'shared/services/tracker/events';
import { ShopExperienceProvider } from 'shared/context/ShopExperience';
import { useLocalOrderInformation } from 'shared/services/OrderInformationService';
import 'shared/services/tracker/consumers';
import { AuthProvider } from 'shared/sso/AuthProvider';
import { PreviewControls } from 'shared/components/Preview';
import EcomClient from 'shared/infra/ecom/client';
import {
  GtmScript,
  GtmConsentScripts,
  GtmGtagScript,
  shouldLoadGtmScript,
} from 'utils/scripts/gtm/gtm';
import {
  CookieConsentScript,
  shouldLoadCookieConsentScript,
} from 'utils/scripts/cookieConsent/cookieConsent';
import { BrowserSupportScript } from 'utils/scripts/browser-support';
import 'utils/tracer';
import { ContentProvider } from 'shared/context/Content';
import { ODLExperimentContext } from 'src/experiments/odl/context';
import { setupWalker } from 'shared/services/walker/setup';

export interface IAppProps {
  catalog?: {
    prices?: Record<string, IProductGalleryItem>;
  };
  content: {};
  prices?: Record<string, IProductGalleryItem>;
  marketReference: string;
  isODLExperimentEnabled?: boolean;
}

type NextPageWithLayout = NextPage<{ children: ReactNode }> & {
  getLayout?: (page: ReactElement) => ReactElement;
};

interface INextAppProps extends Pick<AppProps, 'Component'> {
  pageProps: IAppProps & { children: ReactNode };
  Component: NextPageWithLayout;
}

setupWalker();
const tagger = Tagger();

const App: FC<INextAppProps> = ({ Component, pageProps }) => {
  const dispatch = useDispatch();
  const { pathname, isPreview, locale } = useRouter();
  // TODO migrate all props for pages to have catalog: { prices, products }
  const prices = pageProps.prices || pageProps.catalog?.prices;

  const shouldLoadGtm = shouldLoadGtmScript();
  const shouldLoadCookieConsent = shouldLoadCookieConsentScript();

  // populate catalog with prices
  useEffect(() => {
    if (prices) {
      dispatch(provideCatalog(prices));
    }
  }, [dispatch, prices]);

  // page view
  useEffect(() => {
    dispatchPageViewEvent({});
    // track page views
    elb('walker run');
  }, [pathname]);

  useLocalOrderInformation();

  useEffect(() => {
    if (pageProps.marketReference) {
      EcomClient.init(pageProps.marketReference);
    } else {
      captureException(new Error('Application missing market reference!'));
    }
  }, [pageProps.marketReference]);

  if (Component.getLayout) {
    const { getLayout } = Component;
    return getLayout(<Component {...pageProps} />);
  }

  return (
    <div {...tagger.globals('html_language', locale)}>
      <BrowserSupportScript />
      {shouldLoadCookieConsent && <CookieConsentScript />}
      {shouldLoadGtm && <GtmScript />}
      {shouldLoadGtm && <GtmGtagScript />}
      {shouldLoadCookieConsent && <GtmConsentScripts />}

      <ContentProvider content={{ ...pageProps.content }}>
        <ShopExperienceProvider>
          <OptimizelyProvider>
            <ODLExperimentContext.Provider
              value={{ isEnabled: Boolean(pageProps?.isODLExperimentEnabled) }}
            >
              <ModalProvider>
                {/* This root div is responsible to render the circuit-ui Modal.
                 * The modal uses this as a reference, thus making it necessary on the DOM.
                 */}
                <div id="root" />
                <BaseStyles />
                {isPreview && <PreviewControls />}
                <AuthProvider>
                  <Layout>{<Component {...pageProps} />}</Layout>
                </AuthProvider>
              </ModalProvider>
            </ODLExperimentContext.Provider>
          </OptimizelyProvider>
        </ShopExperienceProvider>
      </ContentProvider>
    </div>
  );
};

const withTheme =
  (Component: ComponentType<unknown>) =>
  <P extends {}>(props: P): JSX.Element =>
    (
      <CacheProvider value={createCache({ key: 'storefront-web' })}>
        <ThemeProvider theme={light}>
          <Component {...props} />
        </ThemeProvider>
      </CacheProvider>
    );

export default withNextRedux(withTheme(App));
