import { WebDestination } from '@elbwalker/walker.js';

declare namespace DestinationSumUpGTM {
  interface Function
    extends WebDestination.Function<CustomConfig, CustomEventConfig> {}

  type Config = WebDestination.Config<CustomConfig, CustomEventConfig>;

  interface CustomConfig {}

  interface CustomEventConfig {}
}

export const destinationSumUpGTM: DestinationSumUpGTM.Function = {
  config: {},

  init() {
    window.dataLayer = window.dataLayer || [];

    return true;
  },

  push(event) {
    const { globals, data } = event;
    // Rename events to snake_case
    const eventName = event.event.replace(' ', '_').toLowerCase();
    const commonProperties = {
      event_timestamp_msec: event.timestamp,
      user_agent: navigator.userAgent,
      ...globals,
    };

    const finalEvent = {
      ...commonProperties,
      ...data,
      event: eventName,
      walker: true,
    };

    window.dataLayer.push(finalEvent);
  },
};
