import Elbwalker, { elb } from '@elbwalker/walker.js';

import { destinationSumUpGTM } from './gtm-destination';

import isServer from 'shared/utils/is-server';

export function setupWalker() {
  if (isServer) {
    return;
  }

  window.elbLayer = [];
  window.elbwalker = Elbwalker();
  window.elb = elb;

  // Google Tag Manager
  elb('walker destination', destinationSumUpGTM, {});
}
