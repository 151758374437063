import { createStore, Store } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';

import { rootReducer } from 'shared/store/rootReducer';
import { IOrderState } from 'shared/store/order/types';
import { IAddressState } from 'shared/store/address/types';
import { ICatalogState } from 'shared/store/catalog/types';
import { PromotionsState } from 'shared/store/promotions/types';
import { IPaymentGlobalState } from 'shared/store/payment/reducers';

export interface IAppState {
  order: IOrderState;
  addresses: IAddressState;
  catalog: ICatalogState;
  promotions: PromotionsState;
  payment: IPaymentGlobalState;
}

export const configStore = (): Store<IAppState> => {
  const store = createStore(rootReducer);

  return store;
};

/**
 * The `useTypedSelector` was created to avoid repeating
 * the IAppState type declaration throughout every place
 * the react-redux's `useSelector` is being used.
 */
export const useTypedSelector: TypedUseSelectorHook<IAppState> = useSelector;

const nextReduxWrapper = createWrapper(configStore, { debug: false });

export const withNextRedux = nextReduxWrapper.withRedux;
